import React, {useState, useEffect} from 'react'
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import baseUrl from "../../api/baseUrl";
import DynamicTable from "./dinamicTable/DynamicTable";
import './AttendanceTable.css';

export default function GeneralReport() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [late, setLate] = useState([]);
    const [generalList, setGeneralList] = useState([]);
    let {auth} = useAuth();
    const buttonStyles = {
        input: {
            padding: '10px',
            fontSize: '14px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            marginBottom: '10px',
            width: '200px',
          },
          label: {
            fontSize: '16px',
            marginRight: '10px',
          },
        container: {
          display: 'flex',
          justifyContent: 'space-around', // Spacing between buttons
          alignItems: 'center', // Align items in the center vertically
          margin: '20px 0',
        },
        common: {
          padding: '10px 20px',
          margin: '5px',
          border: 'none',
          borderRadius: '5px',
          color: '#fff',
          cursor: 'pointer',
          fontSize: '16px',
        },
        today: {
          backgroundColor: '#002F6C',
        },
        thisWeek: {
          backgroundColor: '#47805F',
        },
        thisMonth: {
          backgroundColor: '#F49B45',
        },
        custom: {
          backgroundColor: '#002F6C', // You can change this color if you prefer
        },
      };
      const getClass = (grade_name, combination_name) => {
        const grade = grade_name === "Intwali" ? "S6" :
                      grade_name === "Ishami" ? "S5" :
                      grade_name === "Ijabo" ? "S4" : "EY";
        const comb = (combination_name.match(/\(([^)]+)\)/) || [])[1]?.trim() || combination_name;
        return grade === grade_name ? comb : grade + "_" + comb;
      };
      const groupData = (data) => {
        const grouped = {};
        data.forEach(item => {
            const key = `${item.date}_${item.studentid}_${item.first_name.trim()}_${item.last_name}`;
            if (!grouped[key]) {
                grouped[key] = {
                    combination_name: item.combination_name,
                    date:item.date,
                    family_name:item.family_name,
                    first_name:item.first_name,
                    gender:item.gender==="F"?"Female":"Male",
                    grade_name:item.grade_name,
                    last_name:item.last_name,
                    studentid:item.studentid,
                    activities: []
                };
            }
            grouped[key].activities.push({
                activity: item.activity,
                absenteeism_status: item.absenteeism_status,
                teacher:item.teacher_first_name+" "+item.teacher_last_name
            });
        });
        return Object.values(grouped);
    };
    const separateByAbsenteeismStatus = (data) => {
        const absentData = [];
        const lateData = [];
    
        data.forEach(student => {
            // Separate activities based on absenteeism status
            const absentActivities = student.activities.filter(activity => activity.absenteeism_status === 'absent');
            const lateActivities = student.activities.filter(activity => activity.absenteeism_status === 'late');
    
            if (absentActivities.length > 0) {
                absentData.push({
                    ...student,
                    activities: absentActivities
                });
            }
    
            if (lateActivities.length > 0) {
                lateData.push({
                    ...student,
                    activities: lateActivities
                });
            }
        });
    
        return { absentData, lateData };
    };
    const groupByStudentId = (data) => {
        const grouped = {};
        let i=0;
        data.forEach(item => {
            const studentId = item.studentid;
            
            if (!grouped[studentId]) {
                grouped[studentId] = {
                    "#":++i,
                    studentid: studentId,
                    last_name: item.last_name.trim(),
                    first_name: item.first_name.trim(),
                    class_name:getClass(item.grade_name,item.combination_name),
                    family_name: item.family_name,
                    gender: item.gender,
                    count: 1 // Initialize count to 1 since this student appears at least once
                };
            } else {
                // Increment the count for the student if they already exist in the group
                grouped[studentId].count++;
            }
        });
    
        // Return an array of grouped data
        return Object.values(grouped);
    };
      const getData = async (date1,date2) =>{
        try{
            const response = await axios.get(`${baseUrl}/attendance-report/?date1=${date1}&date2=${date2}`,{
                headers: {
                    "Authorization": 'Bearer ' + String(auth.accessToken),
                    "Content-Type": 'multipart/form-data'
                },
                withCredentials:true 
            });
            const groupedData = groupData(response.data);
            const { absentData, lateData } = separateByAbsenteeismStatus(groupedData);
            const groupedAbsentData = groupByStudentId(absentData);
            console.log(absentData);
            console.log(lateData );
            console.log(groupedAbsentData);
            setData(absentData)
            setLate(lateData)
            setGeneralList(groupedAbsentData)

        }catch(err) {
            console.log(err);
            //navigate('/error');
        }
    }
      const filterToday = () => {
        const today = new Date().toISOString().split('T')[0]; // Format 'YYYY-MM-DD'
        setTitle("Today's Report: "+today)
        getData(today,today)
      };
      const filterWeek = () => {
        const currentDate = new Date();
        
        // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = currentDate.getDay();
        
        // Calculate the start of the week (Monday)
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - dayOfWeek + 1); // Set to Monday of the current week
      
        // Calculate the end of the week (Friday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 4); // Add 4 days to get Friday
      
        // Format dates as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
      
        const startDateFormatted = formatDate(startOfWeek);
        const endDateFormatted = formatDate(endOfWeek);
      
        console.log(`This week (Monday to Friday): ${startDateFormatted} to ${endDateFormatted}`);
        setTitle(`This week (Monday to Friday): ${startDateFormatted} to ${endDateFormatted}`)
        getData(startDateFormatted,endDateFormatted)
      };
      const filterMonth = () => {
        const currentDate = new Date();
      
        // Get the first day of the current month
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      
        // Get the last day of the current month
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0); // Set the day to 0 to get the last day of the previous month
      
        // Format dates as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
      
        const startDateFormatted = formatDate(startOfMonth);
        const endDateFormatted = formatDate(endOfMonth);
      
        console.log(`This month: ${startDateFormatted} to ${endDateFormatted}`);
        setTitle(`This month: ${startDateFormatted} to ${endDateFormatted}`)
        getData(startDateFormatted,endDateFormatted)
      };
      
      const filterCustomDateRange = () => {
        if (startDate && endDate) {
          console.log(`Custom: ${startDate} to ${endDate}`);
          setTitle(`From ${startDate} to ${endDate}`)
          getData(startDate,endDate)
        } else {
          console.log("Select date");
        }
      };
        
  return (
    <div>
        <div style={buttonStyles.container}>
            
            {/* Button to filter today's data */}
            <button
                style={{ ...buttonStyles.common, ...buttonStyles.today }}
                onClick={filterToday}  // Call filterToday on button click
            >
                Today
            </button>
            <button 
                style={{ ...buttonStyles.common, ...buttonStyles.thisWeek }}
                onClick={filterWeek}
            >
                    This Week
            </button>
            <button 
                style={{ ...buttonStyles.common, ...buttonStyles.thisMonth }}
                onClick={filterMonth}
            >
                This Month
            </button>
            <div>
                <h2>Filter Data by Custom Date Range</h2>

                    {/* Input fields for selecting the start and end dates */}
                    <div>
                        <label style={buttonStyles.label}>
                        Start Date:
                        <input
                            type="date"
                            style={buttonStyles.input}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)} // Update start date
                        />
                        </label>
                    </div>

                    <div>
                        <label style={buttonStyles.label}>
                        End Date:
                        <input
                            type="date"
                            style={buttonStyles.input}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)} // Update end date
                        />
                        </label>
                    </div>

                    {/* Button to filter data between the custom date range */}
                    <button
                        style={{ ...buttonStyles.common, ...buttonStyles.custom }}
                        onClick={filterCustomDateRange}  // Call filterCustomDateRange when button is clicked
                    >
                        Filter by Date Range
                    </button>
            </div>
        </div>
        <h2>{title}</h2>
        <div className="attendance-table">
            <h2>1.1 Students Attendance</h2>
            <table>
                <thead>
                <tr>
                    <th>Department</th>
                    <th>Grade</th>
                    <th>Number of girls</th>
                    <th>Number of boys</th>
                    <th>Total</th>
                    <th>%</th>
                    <th>Total girls</th>
                    <th>Total boys</th>
                    <th>Total Number</th>
                </tr>
                </thead>
                <tbody>
                {/* LFHS attendance section */}
                <tr className="section-header">
                    <td rowSpan="4">LFH attendance</td>
                    <td>EY</td>
                    <td>70</td>
                    <td>44</td>
                    <td>114</td>
                    <td className="percentage">89.1%</td>
                    <td>80</td>
                    <td>48</td>
                    <td>128</td>
                </tr>
                <tr>
                    <td>S4</td>
                    <td>77</td>
                    <td>44</td>
                    <td>121</td>
                    <td className="percentage">93.8%</td>
                    <td>81</td>
                    <td>48</td>
                    <td>129</td>
                </tr>
                <tr>
                    <td>S5</td>
                    <td>75</td>
                    <td>48</td>
                    <td>123</td>
                    <td className="percentage">95.3%</td>
                    <td>81</td>
                    <td>48</td>
                    <td>129</td>
                </tr>
                <tr>
                    <td>S6</td>
                    <td>70</td>
                    <td>46</td>
                    <td>116</td>
                    <td className="percentage">92.8%</td>
                    <td>77</td>
                    <td>48</td>
                    <td>125</td>
                </tr>

                {/* LFHS Absenteeism section */}
                <tr className="section-header">
                    <td rowSpan="4">LFHS Absenteeism</td>
                    <td>EY</td>
                    <td>10</td>
                    <td>4</td>
                    <td>14</td>
                    <td className="percentage">10.9%</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>S4</td>
                    <td>4</td>
                    <td>4</td>
                    <td>8</td>
                    <td className="percentage">6.2%</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>S5</td>
                    <td>6</td>
                    <td>0</td>
                    <td>6</td>
                    <td className="percentage">4.7%</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>S6</td>
                    <td>7</td>
                    <td>2</td>
                    <td>9</td>
                    <td className="percentage">7.2%</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            </div>
        <DynamicTable 
            mockdata={generalList} 
        />
        <div>
            <h2>Absenteeism</h2>
        {data.length > 0 ? data.map((st, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            fontWeight: 'bold',
                            gap: '10px',
                            borderBottom: '5px solid #ccc',
                            padding: '5px'
                        }}
                    >
                        <div style={{ fontWeight: 'bold', color: '#6d5736' }}>{index + 1}.</div>
                        <div style={{ fontWeight: 'bold', color: '#6d5736' }}>
                            <h3>{st.first_name} {st.last_name}</h3>
                            <p>{st.studentid}</p>
                            <p>{st.gender==="F"?"Female":"Male"}</p>
                            <p>{getClass(st.grade_name,st.combination_name)}</p>
                            <p>{st.date}</p>
                            </div>
                        
                        {st.activities.length > 0 && st.activities.map((sbj, i) => (
                            <div
                                key={i}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center items within the subject card
                                    textAlign: 'center',   // Center text inside the subject card
                                    gap: '5px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    backgroundColor: i % 2 === 0 ? '#d8b040' : '#f49c46'
                                }}
                            >
                                <h3 style={{ color: '#498160', margin: '4px 0' }}>{sbj.activity}</h3>
                                <p style={{ fontSize: '14px', color: '#6d5736', margin: '2px 0' }}>
                                    status:{sbj.absenteeism_status} <br/> taken by <br/> {sbj.teacher}
                                </p>
                            </div>
                        ))}
                </div>
                
                
                )) : <p>No Data</p>}
            <h2>Lateness</h2>
        {late.length > 0 ? late.map((st, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            fontWeight: 'bold',
                            gap: '10px',
                            borderBottom: '5px solid #ccc',
                            padding: '5px'
                        }}
                    >
                        <div style={{ fontWeight: 'bold', color: '#6d5736' }}>{index + 1}.</div>
                        <div style={{ fontWeight: 'bold', color: '#6d5736' }}>
                            <h3>{st.first_name} {st.last_name}</h3>
                            <p>{st.studentid}</p>
                            <p>{st.gender==="F"?"Female":"Male"}</p>
                            <p>{getClass(st.grade_name,st.combination_name)}</p>
                            <p>{st.date}</p>
                        </div>
                        
                        {st.activities.length > 0 && st.activities.map((sbj, i) => (
                            <div
                                key={i}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center items within the subject card
                                    textAlign: 'center',   // Center text inside the subject card
                                    gap: '5px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    backgroundColor: i % 2 === 0 ? '#d8b040' : '#f49c46'
                                }}
                            >
                                <h3 style={{ color: '#498160', margin: '4px 0' }}>{sbj.activity}</h3>
                                <p style={{ fontSize: '14px', color: '#6d5736', margin: '2px 0' }}>
                                    status:{sbj.absenteeism_status} <br/> taken by <br/> {sbj.teacher}
                                </p>
                            </div>
                        ))}
                </div>
                
                
                )) : <p>No Data</p>}
        </div>
    </div>
  )
}
