import React from 'react'
import LibrarianDashBoard from './LibrarianDashBoard'

export default function AdminDashBoard() {
  return (
    <center>
        <LibrarianDashBoard/>
    </center>
  )
}
